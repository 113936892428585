exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-side-cover-js": () => import("./../../../src/pages/about-side-cover.js" /* webpackChunkName: "component---src-pages-about-side-cover-js" */),
  "component---src-pages-blog-article-2-js": () => import("./../../../src/pages/blog-article-2.js" /* webpackChunkName: "component---src-pages-blog-article-2-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-company-terms-js": () => import("./../../../src/pages/company-terms.js" /* webpackChunkName: "component---src-pages-company-terms-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-customer-services-backup-js": () => import("./../../../src/pages/customer-services-backup.js" /* webpackChunkName: "component---src-pages-customer-services-backup-js" */),
  "component---src-pages-customer-services-js": () => import("./../../../src/pages/customer-services.js" /* webpackChunkName: "component---src-pages-customer-services-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-password-js": () => import("./../../../src/pages/password.js" /* webpackChunkName: "component---src-pages-password-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-sales-js": () => import("./../../../src/pages/sales.js" /* webpackChunkName: "component---src-pages-sales-js" */),
  "component---src-pages-signin-js": () => import("./../../../src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-web-basic-js": () => import("./../../../src/pages/web-basic.js" /* webpackChunkName: "component---src-pages-web-basic-js" */),
  "component---src-templates-blog-article-js": () => import("./../../../src/templates/blog-article.js" /* webpackChunkName: "component---src-templates-blog-article-js" */)
}

